import { Button } from 'react-bootstrap';
import React from 'react';

const EducationalLinks = () => {
  return (
    <div className='thank-you-box-element'>
      <p className='p20 mb10'>
        Now That We’re Helping You With Your Supplemental Coverage, Here Is How
        To Apply For Original Medicare
      </p>
      {/* <h2>We Can Help You Get Started</h2> */}
      <div className='educational-links'>
        <div className='educational-links-btn'>
          <h3>Enrollment</h3>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/how-to-apply-for-medicare/'>
            <Button
              id='how-to-apply-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>How to Apply</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/when-do-you-apply-for-medicare/'>
            <Button
              id='when-to-apply-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>When to Apply</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/how-to-apply-for-medicare-through-spouse/'>
            <Button
              id='spouse-eligibility-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Spouse Eligibility</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/when-do-i-apply-for-medicare-if-im-still-working/'>
            <Button
              id='working-past-65-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Working Past 65</span>
            </Button>
          </a>
        </div>
        <div className='educational-links-btn'>
          <h3>Medicare Parts</h3>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/apply-for-medicare-part-a/'>
            <Button
              id='medicare-part-a-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Medicare Part A</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/apply-for-medicare-part-b/'>
            <Button
              id='medicare-part-b-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Medicare Part B</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/apply-for-medicare-part-c/'>
            <Button
              id='medicare-part-c-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Medicare Part C</span>
            </Button>
          </a>
          <a href='https://www.applyformedicare.com/medicare-enrollment-articles/apply-for-medicare-part-d/'>
            <Button
              id='medicare-part-d-btn'
              variant='btn btn-primary-custom-outline xs-mb15'
              type='submit'
            >
              <span>Medicare Part D</span>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EducationalLinks;
