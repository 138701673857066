import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import PropTypes from 'prop-types';
import React from 'react';

const IFrameVideo = ({ skipStyles }) => {
  return (
    <>
      <div className={!skipStyles ? 'video-wrap' : ''}>
        <div className={!skipStyles ? 'iframe' : ''}>
          <LiteYouTubeEmbed
            id='bJUgZ8_l1Ws'
            title='Play: ApplyForMedicare: We CARE about your medicare'
          ></LiteYouTubeEmbed>
        </div>
      </div>
    </>
  );
};

IFrameVideo.propTypes = {
  skipStyles: PropTypes.bool,
};
IFrameVideo.defaultProps = {
  skipStyles: false,
};
export default IFrameVideo;
